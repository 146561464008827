







































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import store from '@/services/store';
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import { math } from '@amcharts/amcharts4/core';
import { Ax } from '@/utils';
import PowerBiIframe from "@/components/c-power-bi-iframe/Iframe.vue";


@Component({
    name: 'BudgetExecution',
    components: {
        PowerBiIframe,
        'loading': VueElementLoading,
        'c-budg-form-lst': CBudgetFormsList
    }
})
export default class Index extends Vue {

    get region() {
        return store.state._instanceCode;
    }

    private sortBy = 'totalProgress';

    private sortDesc = true;

    private codeRegion: any = '';

    private tableFields = [
        {
            key: 'name',
            label: 'Наименование АБП',
            sortable: true
        },
        {
            key: 'totalLimit',
            label: 'Лимит',
            sortable: false
        },
        {
            key: 'totalFill',
            label: 'Заполнено БЗ',
            sortable: false
        },
        {
            key: 'totalProgress',
            label: '%',
            sortable: true
        },
        {
            key: 'limit',
            label: 'Лимит',
            sortable: false
        },
        {
            key: 'fill',
            label: 'Заполнено БЗ',
            sortable: false
        },
        {
            key: 'progress',
            label: '%',
            sortable: true
        },
        {
            key: 'count',
            label: 'Кол-во, ед.'
        },
        {
            key: 'devLimit',
            label: 'Лимит'
        },
        {
            key: 'devFill',
            label: 'Заполнено БЗ'
        },
        {
            key: 'devProgress',
            label: '%',
            sortable: true
        }
    ]

    private curIndex = 0;

    private up() {
        if (this.curIndex < 2) {
            this.curIndex++;
        }
    }
    private down() {
        if (this.curIndex > 0) {
            this.curIndex--;
        }
    }

    public code: any = null;

    private curFormSelect = '/budget-request';

    public isFetching = false;

    private tableData: any[] = [];

    private programs: any[] = [];

    private region_list: any[] = [];

    private selectedPrg: any = null;

    private ebkList: any[] = [];

    private selectedThirdPrg: any = null;

    private selectedRegion: any = null;

    private selectedFourthPrg: any = null;

    private loading: boolean = false;

    private selectedPeriod: any = '2023-2025';

    private period_list: any[] = ['2023-2025', '2024-2026'];

    private fullSizeOne = false;
    private fullSizeOneTitle = 'ТЕКУЩИЕ РАСХОДЫ – Код наименование АБП';
    private fullSizeTwo = false;
    private fullSizeTwoTitle = 'БЮДЖЕТ РАЗВИТИЯ – Код наименование АБП';
    private fullSizeThree = false;


    private fullSizibleWidgetOne() {
        this.fullSizeOne = !this.fullSizeOne;
    }

    private fullSizibleWidgetTwo() {
        this.fullSizeTwo = !this.fullSizeTwo;
    }

    private fullSizibleWidgetThree() {
        this.fullSizeThree = !this.fullSizeThree;
    }

    private async getFourth() {
        let fourth: any[] = [];
        await fetch('/api-py/budget-request-fourth/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'abp': this.code ? this.code : null,
                'prg': this.selectedThirdPrg ? this.selectedThirdPrg.prg : null,
                'period': this.selectedPeriod
            })
            }).then((response) => response.json())
            .then(json => (fourth = json));
        fourth.forEach((four) => {
            if (four.sum_mb > 0) {
                four.sum_mb = (Math.round((four.sum_mb / 1000) * 10) / 10).toLocaleString()
            }
            if (four.sum_rb > 0) {
                four.sum_rb = (Math.round((four.sum_rb / 1000) * 10) / 10).toLocaleString()
            }
            if (four.value > 0) {
                four.value = (Math.round((four.value / 1000) * 10) / 10).toLocaleString()
            }
            if (four.sum_nf > 0) {
                four.sum_nf = (Math.round((four.sum_nf / 1000) * 10) / 10).toLocaleString();
            }
            if (four.sum_vz > 0) {
                four.sum_vz = (Math.round((four.sum_vz / 1000) * 10) / 10).toLocaleString();
            }
            if (parseInt(four.total) > 0) {
                four.total = (Math.round((parseInt(four.total) / 1000) * 10) / 10).toLocaleString()
            }
        });
        this.tableFour = fourth;
    }

    private async getFourthPrg() {
        let fourth_prg: any[] = [];
        await fetch('/api-py/budget-request-fourth-prg/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'abp': this.code ? this.code : null,
                'period': this.selectedPeriod
            })
            }).then((response) => response.json())
            .then(json => (fourth_prg = json));
        if (fourth_prg) {
            fourth_prg.forEach((four) => {
                four.title = `${four.prg} ${four.name}`
            });
            this.fourthProgram = fourth_prg;
        }
    }

    private async getThirdPrg() {
        let third_prg: any[] = [];
        await fetch('/api-py/budget-request-third-prg/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'abp': this.code ? this.code : null,
                'period': this.selectedPeriod
            })
            }).then((response) => response.json())
            .then(json => (third_prg = json));
        if (third_prg) {
            third_prg.forEach((prg) => {
                prg.title = `${prg.prg} ${prg.name}`
            })
            this.thirdProgram = third_prg;
        }
    }

    private async getThirdContinueCount() {
        let third: any = [];
        await fetch('/api-py/get-budget-request-third-continue-count/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'abp': this.code ? this.code : null,
                'prg': this.selectedThirdPrg ? this.selectedThirdPrg.prg : null,
                'period': this.selectedPeriod
            })
        }).then((response) => response.json())
          .then(json => (third = json));
        this.c_spend.c_count = third.c_count
    }

    private async getThirdContinueLimit() {
        let third: any = [];
        await fetch('/api-py/get-budget-request-third-continue-limit/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'abp': this.code ? this.code : null,
                'prg': this.selectedThirdPrg ? this.selectedThirdPrg.prg : null,
                'period': this.selectedPeriod
            })
        }).then((response) => response.json())
          .then(json => (third = json));
        this.c_spend.c_limit = third.c_limit
    }

    private async getThirdContinueFill() {
        let third: any = [];
        await fetch('/api-py/get-budget-request-third-continue-fill/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'abp': this.code ? this.code : null,
                'prg': this.selectedThirdPrg ? this.selectedThirdPrg.prg : null,
                'period': this.selectedPeriod
            })
        }).then((response) => response.json())
          .then(json => (third = json));
        this.c_spend.c_fill = third.c_fill
    }

    private async getThirdNewCount() {
        let third: any = [];
        await fetch('/api-py/get-budget-request-third-new-count/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'abp': this.code ? this.code : null,
                'prg': this.selectedThirdPrg ? this.selectedThirdPrg.prg : null,
                'period': this.selectedPeriod
            })
        }).then((response) => response.json())
          .then(json => (third = json));
        this.c_spend.n_count = third.n_count
    }

    private async getThirdNewLimit() {
        let third: any = [];
        await fetch('/api-py/get-budget-request-third-new-limit/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'abp': this.code ? this.code : null,
                'prg': this.selectedThirdPrg ? this.selectedThirdPrg.prg : null,
                'period': this.selectedPeriod
            })
        }).then((response) => response.json())
          .then(json => (third = json));
        this.c_spend.n_limit = third.n_limit
    }

    private async getThirdNewFill() {
        let third: any = [];
        await fetch('/api-py/get-budget-request-third-new-fill/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'abp': this.code ? this.code : null,
                'prg': this.selectedThirdPrg ? this.selectedThirdPrg.prg : null,
                'period': this.selectedPeriod
            })
        }).then((response) => response.json())
          .then(json => (third = json));
        this.c_spend.n_fill = third.n_fill
    }

    private async getThird() {
        this.c_spend = {
            c_count: 0,
            c_fill: 0,
            n_count: 0,
            n_fill: 0,
            c_limit: {
                value: 0
            },
            n_limit: {
                value: 0
            }
        }
        await Promise.all([
            this.getThirdContinueCount(),
            this.getThirdContinueLimit(),
            this.getThirdContinueFill(),
            this.getThirdNewCount(),
            this.getThirdNewLimit(),
            this.getThirdNewFill()
        ]);
    }

    private async getFirst() {
        let first: any[] = [];
        await fetch('/api-py/budget-request-first/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'period': this.selectedPeriod
            })
        }).then((response) => response.json())
          .then(json => (first = json));
        first.forEach((list) => {
            list.name = `${list.code} ${list.name}`
            if (list.limit) {
                list.limit = Math.round(list.limit * 10) / 10;
            }
            if (list.fill) {
                list.fill = Math.round((list.fill / 1000) * 10) / 10;
            }
            if (list.devFill) {
                list.devFill = Math.round((list.devFill / 1000) * 10) / 10;
            }
            if (list.devLimit) {
                list.devLimit = Math.round(list.devLimit * 10) / 10;
            }
            if (list.limit && list.fill) {
                list.progress = Math.round((list.fill / list.limit) * 100);
            }
            if (list.devLimit && list.devFill) {
                list.devProgress = Math.round((list.devFill / list.devLimit) * 100);
            }
            list.totalLimit = (list.limit ? list.limit : 0) + (list.devLimit ? list.devLimit : 0);
            list.totalFill = (list.fill ? list.fill : 0) + (list.devFill ? list.devFill : 0);
            if (list.totalFill && list.totalLimit) {
                list.totalProgress = Math.round((list.totalFill / list.totalLimit) * 100);
            } else if (list.totalLimit) {
                list.totalProgress = 0;
            }
            if (list.totalProgress < 1) {
                list._rowVariant = 'color-0';
            } else if (list.totalProgress < 11) {
                list._rowVariant = 'color-0-10';
            } else if (list.totalProgress < 26) {
                list._rowVariant = 'color-11-25';
            } else if (list.totalProgress < 46) {
                list._rowVariant = 'color-26-45';
            } else if (list.totalProgress < 56) {
                list._rowVariant = 'color-46-55';
            } else if (list.totalProgress < 76) {
                list._rowVariant = 'color-56-75';
            } else if (list.totalProgress < 91) {
                list._rowVariant = 'color-76-90';
            } else if (list.totalProgress < 100) {
                list._rowVariant = 'color-91-99';
            } else if (list.totalProgress > 99) {
                list._rowVariant = 'color-100';
            }
        });
        this.tableData = first;
    }

    private async getSecond() {
        this.tableProgress = [];
        let second: any[] = [];
        await fetch('/api-py/budget-request-second/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.selectedRegion ? this.selectedRegion.code : null,
                'period': this.selectedPeriod
            })
            }).then((response) => response.json())
            .then(json => (second = json));
        second.forEach((sec) => {
            sec.name = '';
            const label = this.ebkList.find((x) => parseInt(x.spf) === parseInt(sec.spf))
            if (label) {
                sec.name = label.name;
            }
            if (sec.limit || sec.value) {
                this.tableProgress.push({
                    title: sec.name,
                    spf: sec.spf,
                    valMax: sec.limit ? (sec.limit) : 0,
                    value: sec.value ? (sec.value) : 0,
                    percent: Math.round((sec.value / sec.limit) * 100)
                })
            }
        });
        this.tableTotal = {
            title: 'Итого',
            valMax: 0,
            value: 0,
            percent: Math.round((0 / 0) * 100)
        }
    }

    private async getSecondAbp() {
        this.tableProgress = [];
        await Ax(
            {
                url: '/api-py/get-budget-request-second-abp/', //  Тело файла
                method: 'POST',
                data: {
                    'code': this.selectedRegion ? this.selectedRegion.code : null,
                    'abp': this.code ? parseInt(this.code) : null,
                    'prg': this.selectedPrg ? this.selectedPrg.prg : null,
                    'ppr': this.selectedPpr ? this.selectedPpr.ppr : null,
                    'period': this.selectedPeriod
                },
            },
            (data: any) => {
                let limit = 0;
                let fill = 0;
                data.forEach((dat: any) => {
                    dat.name = '';
                    const label = this.ebkList.find((x) => parseInt(x.spf) === parseInt(dat.spf))
                    if (label) {
                        dat.name = label.name;
                    }
                    if (dat.limit || dat.value) {
                        this.tableProgress.push({
                            title: dat.name,
                            spf: dat.spf,
                            valMax: dat.limit ? (dat.limit / 1000) : 0,
                            value: dat.value ? (dat.value / 1000) : 0,
                            percent: Math.round((dat.value / dat.limit) * 100)
                        })
                        limit += dat.limit / 1000;
                        fill += dat.value / 1000;
                    }
                });
                this.tableTotal = {
                    title: 'Итого',
                    valMax: limit,
                    value: fill,
                    percent: Math.round((fill / limit) * 100)
                }
            },
            (error) => {
                console.log(error)
            }
        );
    }

    private async getRegion() {
        let fifth: any[] = [];
        await fetch('/api-py/budget-request-region/', {
            method: 'POST',
            body: JSON.stringify({
                'code': this.codeRegion.obl,
                'period': this.selectedPeriod
            })
            }).then((response) => response.json())
            .then(json => (fifth = json));
        fifth.forEach((five: any) => {
            this.region_list.push({
                name: five.name,
                code: five.code
            });
            if (five.min) {
                five.min = Math.round((five.min / 1000) * 10) / 10;
            }
            if (five.min_dev) {
                five.min_dev = Math.round((five.min_dev / 1000) * 10) / 10;
            }
            if (five.min_total) {
                five.min_total = Math.round((five.min_total / 1000) * 10) / 10;
            }
            if (five.limit) {
                five.limit = Math.round((five.limit / 1000) * 10) / 10;
            }
            if (five.fill) {
                five.fill = Math.round((five.fill / 1000) * 10) / 10;
            }
            if (five.devFill) {
                five.devFill = Math.round((five.devFill / 1000) * 10) / 10;
            }
            if (five.devLimit) {
                five.devLimit = Math.round((five.devLimit / 1000) * 10) / 10;
            }
            if (five.limit && five.min) {
                five.progress = Math.round((five.min / five.limit) * 100)
            }
            if (five.devLimit && five.min_dev) {
                five.devProgress = Math.round((five.min_dev / five.devLimit) * 100)
            }
            five.totalLimit = (five.limit ? five.limit : 0) + (five.devLimit ? five.devLimit : 0);
            five.totalFill = (five.fill ? five.fill : 0) + (five.devFill ? five.devFill : 0);
            if (five.min_total && five.totalLimit) {
                five.totalProgress = Math.round((five.min_total / five.totalLimit) * 100)
            } else if (five.totalLimit) {
                five.totalProgress = 0;
            }
            if (five.totalProgress < 1) {
                five._rowVariant = 'color-0';
            } else if (five.totalProgress < 11) {
                five._rowVariant = 'color-0-10';
            } else if (five.totalProgress < 26) {
                five._rowVariant = 'color-11-25';
            } else if (five.totalProgress < 46) {
                five._rowVariant = 'color-26-45';
            } else if (five.totalProgress < 56) {
                five._rowVariant = 'color-46-55';
            } else if (five.totalProgress < 76) {
                five._rowVariant = 'color-56-75';
            } else if (five.totalProgress < 91) {
                five._rowVariant = 'color-76-90';
            } else if (five.totalProgress < 100) {
                five._rowVariant = 'color-91-99';
            } else if (five.totalProgress > 99) {
                five._rowVariant = 'color-100';
            }
        });

        this.tableFive = fifth;
        this.isFetching = true;
        this.selectedRegion = this.region_list[0];
    }

    private async getLocation() {
            const code = store.state._instanceCode;
            try {
                await fetch('/api-py/get-budget-obl/' + code)
                    .then(response => response.json())
                    .then(json => {
                        this.codeRegion = json;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getLocation', error.toString());
            }
        }

    private makeToast(variant: any, title: any, tostbody: any) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }

    private async mounted() {
        const date = new Date();
        if (date.getMonth() < 3) {
            this.selectedPeriod = '2023-2025'
        } else {
            this.selectedPeriod = '2024-2026'
        }
        this.$watch('selectedPeriod', async () => {
            this.getFirst();
            this.getSecond();
            this.getThird();
            this.getFourth();
            await this.getRegion();
            await this.getPprList();
            if (this.code) {
                this.getSecondAbp();
            }
        })
        await this.getLocation();
        const region = {
            name: this.$t('mainText' + this.codeRegion.code),
            code: this.codeRegion.region
        }
        this.region_list.unshift(region);
        this.$watch('selectedRegion', async () => {
            this.code = null;
            this.getFirst();
            this.getSecond();
            this.getThird();
            this.getFourth();
            if (this.code) {
                this.getSecondAbp();
            }
        })
        this.$watch('selectedPrg', async () => {
            if (this.selectedPrg) {
                await this.getPprList();
            }
            await this.getSecondAbp();
            this.selectedPpr = null;
        });
        this.$watch('selectedPpr', async () => {
            await this.getSecondAbp()
        })
        this.$watch('selectedThirdPrg', async () => {
            await this.getThird();
            await this.getFourth();
        })
        this.$watch('selectedFourthPrg', async () => {
            await this.getFourth();
        })
        let test: any[] = [];
        await fetch('/api-py/budget-request-ebk')
            .then(response => response.json())
            .then(json => (test = json));
        this.ebkList = test;
        await this.getFirst()
        await this.getSecond();
        await this.getFourth();
        await this.getRegion();

    }

    private tableFive: any[] = [];

    private tableFour: any[] = [];

    private allProgress: any[] = [];

    private subProgram: any = [];

    private thirdProgram: any[] = [];

    private fourthProgram: any[] = [];

    private c_spend: any = [];

    private async selectCode(item: any) {
        this.tableData.forEach((data) => {
            if (data._rowVariant) {
                if (data._rowVariant.split(' ').length > 1) {
                    data._rowVariant = data._rowVariant.split(' ')[0]
                }
            }
        });
        if (this.code === item.code) {
            this.code = null;
            this.secondLabel = 'Все АБП';
            this.tableTotal = {}
            this.getSecond();
            this.getThird();
            this.getFourth();
        } else {
            item._rowVariant += ' active'
            this.code = item.code;
            this.selectedThirdPrg = null;
            this.tableProgress = [];
            this.programs = [];
            this.subProgram = [];
            this.selectedPrg = null;
            this.selectedPpr = null;
            await this.getPrgList();
            await this.getFourth();
            await this.getFourthPrg();
            await this.getSecondAbp();
            this.secondLabel = item.name;
            this.getPprName();
            this.allProgress = this.tableProgress;
            await this.getThird();
            await this.getThirdPrg();
        }
        this.tableTotal = {
            title: 'Итого',
            valMax: item.limit ? item.limit : 0,
            value: item.fill ? item.fill : 0,
            percent: Math.round((item.fill / item.limit) * 100)
        }
    }

    private async getPrgList() {
        Ax(
            {
                url: '/api-py/get-budget-request-prg-list/', //  Тело файла
                method: 'POST',
                data: {
                    'code': this.selectedRegion ? this.selectedRegion.code : null,
                    'abp': parseInt(this.code),
                    'period': this.selectedPeriod
                },
            },
            (data: any) => {
                this.programs = data;
                this.getPrgName();
            },
            (error) => {
                console.log(error)
            }
        );
    }

    private getPrgName() {
        this.programs.forEach((prog: any) => {
            Ax(
                {
                    url: '/api-py/get-budget-request-prg/', //  Тело файла
                    method: 'POST',
                    data: {
                        'abp': parseInt(this.code),
                        'prg': parseInt(prog.prg),
                        'ppr': null,
                        'period': this.selectedPeriod
                    },
                },
                (data: any) => {
                    prog.prgName = '';
                    prog.prgName = `${prog.prg} ${data[0].name}`
                },
                (error) => {
                    console.log(error)
                }
            );
        })
        this.programs = this.programs.filter(x => x.prgName !== '')
    }

    private async getPprList() {
        Ax(
            {
                url: '/api-py/get-budget-request-ppr-list/', //  Тело файла
                method: 'POST',
                data: {
                    'code': this.selectedRegion ? this.selectedRegion.code : null,
                    'abp': parseInt(this.code),
                    'prg': parseInt(this.selectedPrg.prg),
                    'period': this.selectedPeriod
                },
            },
            (data: any) => {
                this.subProgram = data;
                this.getPprName();
            },
            (error) => {
                console.log(error)
            }
        );
    }

    private getPprName() {
        this.subProgram.forEach((prog: any) => {
            Ax(
                {
                    url: '/api-py/get-budget-request-prg/', //  Тело файла
                    method: 'POST',
                    data: {
                        'abp': parseInt(this.code),
                        'prg': parseInt(this.selectedPrg.prg),
                        'ppr': parseInt(prog.ppr),
                        'period': this.selectedPeriod
                    },
                },
                (data: any) => {
                    prog.pprName = '';
                    prog.pprName = `${prog.ppr} ${data[0].name}`
                },
                (error) => {
                    console.log(error)
                }
            );
        })
        this.subProgram = this.subProgram.filter((x: any) => x.pprName !== '');
    }

    private secondLabel: any = 'Все АБП';

    private tableProgress: any[] = []

    private percentCalc(valMax: any, val: any) {
        return Math.round((val / valMax) * 100);
    }

    private calcValue(val: any, percent: any) {
        return Math.round((val / 100) * percent);
    }

    private selectedPpr: any = null;

    private filterData() {
        if (this.selectedPrg) {
            this.tableProgress = this.allProgress.filter((x) => parseInt(x.prg) === parseInt(this.selectedPrg.prg));
        } else {
            this.selectedPpr = null;
        }
        if (this.selectedPpr) {
            this.tableProgress = this.allProgress.filter((x) => parseInt(x.ppr) === parseInt(this.selectedPpr.ppr));
        }
        if (!this.selectedPrg && !this.selectedPpr) {
            this.tableProgress = this.allProgress;
        }
    }

    private tableTotal: any = {}

    private tableFields2 = [
        {
            key: 'name',
            label: 'Наименование',
        },
        {
            key: 'total',
            label: 'Общая стоимость',
        },
        {
            key: 'value',
            label: '',
        },
        {
            key: 'sum_mb',
            label: 'МБ',
        },
        {
            key: 'sum_rb',
            label: 'РБ',
        },
        {
            key: 'sum_nf',
            label: 'НФ',
        },
        {
            key: 'sum_vz',
            label: 'ВЗ',
        },
    ]

    private tab = 0;
}
